.snack-bar-wrapper {
  margin-top: 70px !important;
   background-color: #FFFFFF !important;
   color : beige;
  &.error {
    border: 1px solid #E74C3C;
    color: #E74C3C !important;
  }

  &.success {
    border: 1px solid #2ECC71;
    color: #2ECC71 !important;
  }

  &.warning {
    border: 1px solid #F39C12;
    color: #F39C12 !important;
  }
}
