@font-face {
  font-family: Helvetica-light;
  src: url(../fonts/helvetica-neue-condensed-otf/hn-light-condensed.otf) format("opentype");
}

@font-face {
  font-family: Helvetica;
  src: url(../fonts/helvetica-neue-condensed-otf/hn-condensed.otf) format("opentype");
}

@font-face {
  font-family: Helvetica-bold;
  src: url(../fonts/helvetica-neue-condensed-otf/hn-bold-condensed.otf) format("opentype");
}

@font-face {
  font-family: Helvetica-medium;
  src: url(../fonts/helvetica-neue-condensed-otf/hn-medium-condensed.otf) format("opentype");
}

@font-face {
  font-family: Helvetica-thin;
  src: url(../fonts/helvetica-neue-condensed-otf/hn-thin-condensed.otf) format("opentype");
}
